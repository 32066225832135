<template>
  <div>
    <div class="page-banner">
      <img
        :src="this.getSiteProps('clubinnerpages.headerbanner')"
        style="width:100%;"
        alt="page-banner"
      />
      <div class="page-title">
        <h5 class="text-white">FAQ</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 faq-page mb-4">
        <div
          v-for="(faqitem1, parentIndex) in clubFaqitem1"
          :key="parentIndex"
          class="accordion"
          role="tablist"
        >
          <h3 class="faq-title">{{ faqitem1.title }}</h3>
          <b-card
            no-body
            class="mb-1"
            v-for="(colitem1, childIndex) in faqitem1.items"
            :key="'types' + childIndex"
          >
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle="'accordion-' + childIndex + parentIndex"
                variant="info"
                >{{ colitem1.name }}</b-button
              >
            </b-card-header>
            <b-collapse
              :id="'accordion-' + childIndex + parentIndex"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text v-html="colitem1.desc"></b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <div class="col-md-6 faq-page mb-4">
        <div v-for="(faqitem2, parentIndex) in clubFaqitem2" :key="parentIndex">
          <div class="accordion" role="tablist">
            <h3 class="faq-title">{{ faqitem2.title }}</h3>
            <b-card
              no-body
              class="mb-1"
              v-for="(colitem2, childIndex) in faqitem2.items"
              :key="'types' + childIndex"
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle="'accordion-dp-' + childIndex + parentIndex"
                  variant="info"
                  >{{ colitem2.name }}</b-button
                >
              </b-card-header>
              <b-collapse
                :id="'accordion-dp-' + childIndex + parentIndex"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text v-html="colitem2.desc"></b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "memberfaq",

  data() {
    return {
      clubFaqitem1: null,
      clubFaqitem2: null,
    };
  },

  beforeUpdate() {
    this.clubFaqitem1 = this.getSiteProps("clubfaq.col1");
    this.clubFaqitem2 = this.getSiteProps("clubfaq.col2");
  },
  updated() {
    console.log("Updated");
  },
};
</script>


